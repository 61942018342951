import { uuid } from '@interacta-shared/util';
import { UsefulLink, UsefulLinksGroup } from './useful-link.model';
import { isUsefulLinkVisibleInWeb } from './useful-link.utils';

export const toUsefulLink = (item: any): UsefulLink => {
    return {
        uuid: item.uuid ?? uuid(),
        name: item.name,
        url: item.url,
        mobileUrl: item.mobileUrl,
        communityId: item.communityId ?? undefined,
        contentRef: item.contentRef,
        contentMimeType: item.contentMimeType,
        temporaryContentPreviewImageLink: item.temporaryContentPreviewImageLink,
        temporaryContentViewLink: item.temporaryContentViewLink,
    };
};

export const toUsefulLinkGroup = (
    item: any,
    webOnly = false,
): UsefulLinksGroup => {
    return {
        uuid: item.uuid ?? uuid(),
        name: item.name,
        links: (<Array<any> | undefined>item.links ?? [])
            .map(toUsefulLink)
            .filter((link) => !webOnly || isUsefulLinkVisibleInWeb(link)),
    };
};
