/* eslint-disable @typescript-eslint/no-unsafe-call */
import { AuthUser } from '@interacta-shared/data-access-auth';
import {
    ConfigurationService,
    toZonedDatetime,
} from '@interacta-shared/data-access-configuration';
import { assertUnreachable, uuid } from '@interacta-shared/util';
import { isMediaImage } from '@interacta-shared/util-common';
import {
    CommunityTreeDeserialize,
    ICommunity,
} from '@modules/communities/models/communities.model';
import {
    IUsersGroup,
    UserDeserilize,
    UserExtendedDeserialize,
} from '@modules/core';
import { defaultCoverWidgetMobileContentAttachment } from '@modules/digital-workplace-admin/models/digital-workplace-admin.utils';
import { BasePostDeserialize } from '@modules/post/models/base-post.model';
import { IComment } from '@modules/post/models/comment.model';
import { IPostFilters } from '@modules/post/models/filter-post/filter-post.model';
import { emptyPostFilters } from '@modules/post/models/filter-post/filter-post.utils';
import { toPartialPostFilter } from '@modules/post/models/quick-filters/quick-filters.deserialize';
import { toUsefulLinkGroup } from '@modules/useful-link/models/useful-link.deserialize';
import {
    CallToAction,
    CallToActionWidget,
    DigitalWorkplaceBaseWidget,
    DigitalWorkplaceDefinition,
    DigitalWorkplaceDefinitionVisibility,
    DigitalWorkplaceStatus,
    DigitalWorkplaceWidget,
    MentionedInMentionedGroup,
    MentionedInMostMentioned,
    MobileOptionsType,
    MySpaceCounters,
    MySpaceCountersDrafts,
    MySpaceCountersMentionedIn,
    MySpaceCountersProcesses,
    MySpaceCountersTasks,
    MySpaceWidget,
    PostListCoverWidget,
    PostListCoverWidgetCardSettingsBase,
    PostRollBaseWidget,
    PostRollWidgetCardSettingsBase,
    StaticCoverExternalUrlType,
    StaticCoverWidget,
    StaticCoverWidgetContent,
    StaticCoverWidgetContentAttachment,
    StaticCoverWidgetContentAttachmentMetadata,
    StaticCoverWidgetMobileContentAttachment,
    TextPostRollWidget,
    TextPostRollWidgetCardSettings,
    UsefulLinkWidget,
    VisualPostRollWidget,
    WidgetCardSettingsBase,
    WidgetType,
} from './digital-workplace.model';

export const toDigitalWorkplaceDefinition = (
    record: any,
    configurationService: ConfigurationService,
    communitiesList: ICommunity[],
    webOnly = false,
): DigitalWorkplaceDefinition => {
    return {
        id: record.id,
        name: record.name,
        active: record.active ?? true,
        publicationDate: record.publicationTimestamp
            ? new Date(record.publicationTimestamp)
            : null,
        lastModifyTimestamp: record.lastModifyTimestamp,
        viewedTimestamp: record.viewedTimestamp,
        creationDate: record.creationTimestamp
            ? new Date(record.creationTimestamp)
            : null,
        position: record.position,
        status: record.status ?? [DigitalWorkplaceStatus.DRAFT],
        publishedHomeId: record.publishedId,
        visibility: toDigitalWorkplaceDefinitionVisibility(record.visibility),
        widgets: (record.widgets ?? []).map((w: any) =>
            toDigitalWorkplaceWidget(
                w,
                configurationService,
                communitiesList,
                webOnly,
            ),
        ),
    };
};

export const toDigitalWorkplaceDefinitionVisibility = (
    record?: any,
): DigitalWorkplaceDefinitionVisibility => {
    return {
        global: !!record?.global,
        workspaces: (record?.workspaces ?? []).map((w: any) => ({
            id: w.id,
            name: w.name,
        })),
        communities: (record?.communities ?? []).map(
            CommunityTreeDeserialize.communityInfoDetails,
        ),
        groups: (record?.groups ?? []).map(UserExtendedDeserialize.usersGroup),
    };
};

export const toDigitalWorkplaceWidget = (
    record: any,
    configurationService: ConfigurationService,
    communitiesList: ICommunity[],
    webOnly = false,
): DigitalWorkplaceWidget => {
    const type = record.type as WidgetType;
    switch (type) {
        case WidgetType.VISUAL_POST_ROLL:
            return toVisualPostRollWidget(record, communitiesList);
        case WidgetType.TEXT_POST_ROLL:
            return toTextPostRollWidget(record, communitiesList);
        case WidgetType.POST_LIST_COVER:
            return toPostListCoverWidget(record, communitiesList);
        case WidgetType.STATIC_COVER:
            return toStaticCoverWidget(record, configurationService);
        case WidgetType.USEFUL_LINK:
            return toUsefulLinkWidget(record, webOnly);
        case WidgetType.CALL_TO_ACTION:
            return toCallToActionWidget(record, communitiesList);
        case WidgetType.MY_SPACE:
            return toMySpaceWidget(record);
        default:
            assertUnreachable(type);
    }
};

const toBaseWidget = (record: any): DigitalWorkplaceBaseWidget => {
    return {
        id: record.id,
        name: record.name,
        position: record.position,
        active: record.active ?? true,
        type: record.type,
    };
};

export const toVisualPostRollWidget = (
    record: any,
    communitiesList: ICommunity[],
): VisualPostRollWidget => {
    const communityIds = record.visualPostRollConfiguration?.communityIds ?? [];
    const foundedCommunity =
        communityIds.length === 1
            ? communitiesList?.find((c) => communityIds[0] === c.id) ?? null
            : null;
    return {
        ...toBaseWidget(record),
        tag: WidgetType.VISUAL_POST_ROLL,
        size: postRollConfigurationToSize(
            record.visualPostRollConfiguration?.cardSize,
        ),
        communityIds,
        filters: widgetConfigurationToPostFilters(
            record.visualPostRollConfiguration?.postFilters,
            foundedCommunity,
        ),
        cardSettings: {
            ...toBasePostRollWidgetCardSettingsBase(
                record.visualPostRollConfiguration,
            ),
        },
    };
};

export const toTextPostRollWidget = (
    record: any,
    communitiesList: ICommunity[],
): TextPostRollWidget => {
    const customFieldIds = record.textPostRollConfiguration?.fieldIds ?? [];
    const communityIds = record.textPostRollConfiguration?.communityIds ?? [];
    const foundedCommunity =
        communityIds.length === 1
            ? communitiesList?.find((c) => communityIds[0] === c.id) ?? null
            : null;
    return {
        ...toBaseWidget(record),
        tag: WidgetType.TEXT_POST_ROLL,
        size: postRollConfigurationToSize(
            record.textPostRollConfiguration?.cardSize,
        ),
        communityIds,
        filters: widgetConfigurationToPostFilters(
            record.textPostRollConfiguration?.postFilters,
            foundedCommunity,
        ),
        customFieldIds,
        cardSettings: {
            ...toTextPostRollWidgetCardSettings(
                record.textPostRollConfiguration,
                customFieldIds.length > 0,
            ),
        },
    };
};

const toWidgetCardSettingsBase = (
    configuration: any | undefined,
): WidgetCardSettingsBase => {
    return {
        showCommunity: configuration?.showCommunity ?? false,
        showWorkspace: configuration?.showWorkspace ?? false,
        showLikesCounter: configuration?.showLikesCounter ?? false,
        showViewsCounter: configuration?.showViewsCounter ?? false,
        showCommentsCounter: configuration?.showCommentsCounter ?? false,
        showAttachmentsCounter: configuration?.showAttachmentsCounter ?? false,
        showStandardTasksCounter:
            configuration?.showStandardTasksCounter ?? false,
        showAuthor: configuration?.showAuthor ?? false,
        showPublicationTimestamp:
            configuration?.showPublicationTimestamp ?? false,
    };
};

const toBasePostRollWidgetCardSettingsBase = (
    configuration: any | undefined,
): PostRollWidgetCardSettingsBase => {
    return {
        ...toWidgetCardSettingsBase(configuration),
        showTitle: configuration?.showTitle ?? false,
        showCustomId: configuration?.showCustomId ?? false,
        showState: configuration?.showState ?? false,
    };
};
const toTextPostRollWidgetCardSettings = (
    configuration: any | undefined,
    showCustomFields: boolean,
): TextPostRollWidgetCardSettings => {
    return {
        ...toBasePostRollWidgetCardSettingsBase(configuration),
        showDescription: configuration?.showDescription ?? false,
        showCustomFields,
    };
};

const postRollConfigurationToSize = (
    size: string | undefined,
): PostRollBaseWidget['size'] => {
    switch (size) {
        case 'SMALL':
            return 'small';
        case 'MEDIUM':
            return 'regular';
        case 'LARGE':
            return 'large';
        default:
            return 'regular';
    }
};

const widgetConfigurationToPostFilters = (
    filters: string | undefined,
    community: Pick<ICommunity, 'id' | 'metadata'> | null,
): IPostFilters => {
    return {
        ...emptyPostFilters(),
        ...(filters != null ? toPartialPostFilter(filters, community) : {}),
    };
};

const toPostListCoverWidgetCardSettingsBase = (
    configuration: any | undefined,
): PostListCoverWidgetCardSettingsBase => {
    return {
        ...toWidgetCardSettingsBase(configuration),
    };
};

export const toPostListCoverWidget = (
    record: any,
    communitiesList: ICommunity[],
): PostListCoverWidget => {
    const communityIds: number[] =
        record.postListCoverConfiguration?.communityIds ?? [];
    const foundedCommunity =
        communityIds.length === 1
            ? communitiesList?.find((c) => communityIds[0] === c.id) ?? null
            : null;
    return {
        ...toBaseWidget(record),
        tag: WidgetType.POST_LIST_COVER,
        communityIds,
        size: record.postListCoverConfiguration.size,
        appearance: record.postListCoverConfiguration.type,
        mainCover: record.postListCoverConfiguration.mainCover,
        filters: widgetConfigurationToPostFilters(
            record.postListCoverConfiguration?.postFilters,
            foundedCommunity,
        ),
        cardSettings: toPostListCoverWidgetCardSettingsBase(
            record.postListCoverConfiguration,
        ),
        maxVisiblePosts:
            record.postListCoverConfiguration?.maxVisiblePosts ?? 10,
    };
};

export const toStaticCoverWidget = (
    record: any,
    configurationService: ConfigurationService,
): StaticCoverWidget => {
    return {
        ...toBaseWidget(record),
        tag: WidgetType.STATIC_COVER,
        size: record.staticCoverConfiguration.size,
        appearance: record.staticCoverConfiguration.type,
        mainCover: record.staticCoverConfiguration.mainCover,
        staticContents: (
            record.staticCoverConfiguration.staticContents || []
        ).map((staticContent: any) =>
            toStaticContent(staticContent, configurationService),
        ),
    };
};

export const toUsefulLinkWidget = (
    record: any,
    webOnly = false,
): UsefulLinkWidget => ({
    ...toBaseWidget(record),
    tag: WidgetType.USEFUL_LINK,
    linksGroups: (
        <Array<any> | undefined>record.usefulLinkConfiguration.linksGroups ?? []
    )
        .map((g) => toUsefulLinkGroup(g, webOnly))
        .filter(({ links }) => links.length > 0),
    //IISP-8691
    //La proprietà visibility è implementata lato BE solo per UsefulLinkWidget
    groups: (record.visibility?.groups || []).map(
        UserExtendedDeserialize.usersGroup,
    ),
    users: (record.visibility?.users || []).map(UserDeserilize.userDetails),
});

export const toCallToAction = (
    item: any,
    communitiesList: ICommunity[] | undefined,
): CallToAction => {
    const foundedCommunity =
        communitiesList?.find((c) => item.communityId === c.id) ?? null;
    return {
        uuid: item.uuid ?? uuid(),
        name: item.name,
        communityId: item.communityId,
        contentRef: item.contentRef,
        contentMimeType: item.contentMimeType,
        temporaryContentPreviewImageLink: item.temporaryContentPreviewImageLink,
        temporaryContentViewLink: item.temporaryContentViewLink,
        temporaryContentPreviewImageHiResLink:
            item.temporaryContentPreviewImageHiResLink,
        actionLabel: item.actionLabel,
        actionType: item.actionType,
        advancedMobileOptions: toAdvancedMobileOptions(
            item.advancedMobileOptions,
        ),
        showExamplePost: item.showExamplePost ?? false,
        examplePostData: item.examplePostData
            ? {
                  filters: widgetConfigurationToPostFilters(
                      item.examplePostData.postFilters,
                      foundedCommunity,
                  ),
                  fieldIds: item.examplePostData.fieldIds ?? [],
              }
            : undefined,
    };
};

export const toCallToActionWidget = (
    record: any,
    communitiesList: ICommunity[],
): CallToActionWidget => {
    return {
        ...toBaseWidget(record),
        tag: WidgetType.CALL_TO_ACTION,
        callToActions: (
            record.callToActionConfiguration.callToActions ?? []
        ).map((c: any) => toCallToAction(c, communitiesList)),
    };
};

export const toMySpaceWidget = (record: any): MySpaceWidget => {
    return {
        ...toBaseWidget(record),
        tag: WidgetType.MY_SPACE,
        size: record.mySpaceConfiguration?.small ? 'small' : 'large',
        groups: (record.visibility?.groups || []).map(
            UserExtendedDeserialize.usersGroup,
        ),
        users: (record.visibility?.users || []).map(UserDeserilize.userDetails),
    };
};

const toAdvancedMobileOptions = (
    advancedMobileOptions?: any,
): CallToAction['advancedMobileOptions'] => {
    if (!advancedMobileOptions) return undefined;

    if (advancedMobileOptions.type === MobileOptionsType.CAMERA) {
        return {
            type: MobileOptionsType.CAMERA,
            fieldId: advancedMobileOptions.fieldId,
            postAttachments: advancedMobileOptions.postAttachments ?? false,
        };
    } else {
        return {
            type: MobileOptionsType.QR_CODE,
            fieldId: advancedMobileOptions.fieldId,
        };
    }
};

const toStaticCoverWidgetContentAttachment = (
    item: any,
): StaticCoverWidgetContentAttachment => {
    const isImage = isMediaImage(item.contentMimeType);

    const metadata: StaticCoverWidgetContentAttachmentMetadata | undefined =
        item.metadata;

    const fillType = isImage ? metadata?.fill_type ?? 'fit' : undefined;

    const alignment =
        isImage && fillType === 'fill'
            ? metadata?.alignment ?? 'center'
            : undefined;

    return {
        temporaryContentPreviewImageHiResLink:
            item.temporaryContentPreviewImageHiResLink,
        temporaryContentViewLink: item.temporaryContentViewLink,
        contentRef: item.contentRef,
        contentMimeType: item.contentMimeType,
        fillType,
        alignment,
        secondsToStart: metadata?.start_at_seconds ?? undefined,
    };
};
const toStaticCoverWidgetMobileContentAttachment = (
    item: any,
): StaticCoverWidgetMobileContentAttachment => {
    return {
        ...toStaticCoverWidgetContentAttachment(item),
        webMobileSameImage: isMediaImage(item.contentMimeType)
            ? item.metadata?.web_mobile_same_image === 'true'
            : undefined,
    };
};

const toStaticContent = (
    item: any,
    configurationService: ConfigurationService,
): StaticCoverWidgetContent => {
    const staticContent: StaticCoverWidgetContent = {
        id: item.uuid ?? uuid(),
        name: item.name,
        ...toStaticCoverWidgetContentAttachment(item),
        externalUrlType: item.externalUrlType,
        externalUrl: item.externalUrl,
        actionLabel: item.actionLabel,
        actionUrl: item.actionUrl,
        youtubeVideoId:
            item.externalUrlType === StaticCoverExternalUrlType.YOUTUBE
                ? toYoutubeVideoId(item.externalUrl)
                : undefined,
        startDisplay: item.startDisplay?.localDatetime
            ? toZonedDatetime(item.startDisplay, configurationService)
            : null,
        endDisplay: item.endDisplay?.localDatetime
            ? toZonedDatetime(item.endDisplay, configurationService)
            : null,
        displayDateTimezone: item.displayDatetimeTimezone
            ? configurationService.getTimezone(item.displayDatetimeTimezone)
            : null,
        deleted: false,
        mobileStaticContent: null,
    };
    staticContent.mobileStaticContent = item.mobileStaticContent
        ? toStaticCoverWidgetMobileContentAttachment(item.mobileStaticContent)
        : defaultCoverWidgetMobileContentAttachment(staticContent);
    return staticContent;
};

export const youtubeThumbnailImageByUrl = (url: string): string | null => {
    const videoID = toYoutubeVideoId(url);

    return videoID
        ? `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`
        : null;
};

export const toYoutubeVideoId = (
    url: string | undefined,
): string | undefined => {
    if (url) {
        const regex1 =
            /(?:[?&]v=|\/embed\/|\/\d+\/|\/vi?\/|https?:\/\/(?:www\.)?youtu\.be\/)([^&\n?#]+)/;
        const regex2 =
            /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;

        const match = url.match(regex1) || url.match(regex2);
        return match && match[1] ? match[1] : undefined;
    } else {
        return undefined;
    }
};

export const mySpaceCountersDeserialize = (record: any): MySpaceCounters => ({
    commentReplies: record.commentReplies,
    createdByMeClosedTasks: record.createdByMeClosedTasks,
    createdByMeExpiredTasks: record.createdByMeExpiredTasks,
    createdByMeExpiringTasks: record.createdByMeExpiringTasks,
    expiredTasks: record.expiredTasks,
    expiringTasks: record.expiringTasks,
    mentionedInPostAndComments: record.mentionedInPostAndComments,
    scheduledDraftPosts: record.scheduledDraftPosts,
    standardDraftPosts: record.standardDraftPosts,
    toManageWorkflowPosts: record.toManageWorkflowPosts,
});

export const mySpaceCountersProcessesDeserialize = (
    record: any,
): MySpaceCountersProcesses => ({
    communityCounters: record.communityCounters.map((item: any) => {
        return {
            community: {
                id: item.community.id,
                name: item.community.name,
            },
            today: item.today,
            lastWeek: item.lastWeek,
            lastMonth: item.lastMonth,
            other: item.other,
        };
    }),
});

export const mySpaceCountersTasksDeserialize = (
    record: any,
): MySpaceCountersTasks => ({
    assignedToMeExpired: record.assignedToMeExpired,
    assignedToMeExpiringToday: record.assignedToMeExpiringToday,
    assignedToMeExpiringTomorrow: record.assignedToMeExpiringTomorrow,
    assignedToMeExpiringThisWeek: record.assignedToMeExpiringThisWeek,
    createdByMeExpiredTasks: record.createdByMeExpiredTasks.map((item: any) => {
        return {
            count: item.count,
            entity: {
                id: item.entity.id,
                user: item.entity.user
                    ? userEntityDeserialize(item.entity.user)
                    : null,
                group: item.entity.group
                    ? userGroupEntityDeserialize(item.entity.group)
                    : null,
            },
        };
    }),
    createdByMeClosedTasks: record.createdByMeClosedTasks.map((item: any) => {
        return {
            count: item.count,
            entity: userEntityDeserialize(item.entity),
        };
    }),
});

export const mySpaceCountersDraftsDeserialize = (
    record: any,
    configurationService: ConfigurationService,
): MySpaceCountersDrafts => ({
    staleStandardDraftPosts: record.staleStandardDraftPosts,
    publishingScheduledDraftPosts:
        record.publishingScheduledDraftPosts &&
        record.publishingScheduledDraftPosts.map((item: any) => {
            return {
                id: item.id,
                title: item.title,
                scheduledPublication: item.scheduledPublication
                    ? BasePostDeserialize.toScheduledPublication(
                          item.scheduledPublication,
                          configurationService,
                      )
                    : null,
            };
        }),
});

const userEntityDeserialize = (
    record: any,
): Pick<AuthUser, 'id' | 'firstName' | 'lastName'> => ({
    id: record.id,
    firstName: record.firstName,
    lastName: record.lastName,
});

const userGroupEntityDeserialize = (
    record: any,
): Pick<IUsersGroup, 'id' | 'fullName'> => ({
    id: record.id,
    fullName: record.name,
});

const mostMentionerDeserialize = (record: any): MentionedInMostMentioned => ({
    count: record.count,
    entity: userEntityDeserialize(record.entity),
});

const mentionedGroupDeserialize = (record: any): MentionedInMentionedGroup => ({
    count: record.count,
    entity: userGroupEntityDeserialize(record.entity),
});

const lastWeekMostRepliedCommentDeserialize = (
    record: any,
): Pick<IComment, 'id' | 'plainText'> => ({
    id: record.id,
    plainText: record.plainText,
});

export const mySpaceCountersMentionedInDeserialize = (
    record: any,
): MySpaceCountersMentionedIn => ({
    mostMentioner: record.mostMentioner
        ? mostMentionerDeserialize(record.mostMentioner)
        : null,
    lastMonthMentioners: record.lastMonthMentioners.map((entity: any) => {
        return userEntityDeserialize(entity);
    }),
    todayMentionerUser: record.todayMentionerUser
        ? userEntityDeserialize(record.todayMentionerUser)
        : null,
    todayMentionedGroup: record.todayMentionedGroup
        ? mentionedGroupDeserialize(record.todayMentionedGroup)
        : null,
    lastMonthMostMentionedGroup: record.lastMonthMostMentionedGroup
        ? mentionedGroupDeserialize(record.lastMonthMostMentionedGroup)
        : null,
    lastWeekRepliedCommentsCount: record.lastWeekRepliedCommentsCount,
    todayRepliedCommentsCount: record.todayRepliedCommentsCount,
    onFireComment: record.onFireComment
        ? lastWeekMostRepliedCommentDeserialize(record.onFireComment)
        : null,
});
