import { InputSelectItem } from '@interacta-shared/ui';
import { areEqualObjects, uuid } from '@interacta-shared/util';
import { isMediaImage } from '@interacta-shared/util-common';
import {
    CallToAction,
    PostListWidget,
    StaticCoverWidgetMobileContentAttachment,
} from '@modules/digital-workplace/models/digital-workplace.model';
import { IPostMetadata } from '@modules/post/models/base-post.model';
import {
    UsefulLink,
    UsefulLinksGroup,
} from '@modules/useful-link/models/useful-link.model';
import {
    CoverWidgetStep,
    CtaWidgetStep,
    StaticCoverContentAlignmentOption,
    StaticCoverContentFillTypeOption,
    StaticCoverWidgetContentLocal,
    UsefulLinkTypeByDevice,
    WidgetCounterConfiguration,
    WidgetCreateButton,
} from './digital-workplace-admin.model';

export const coverWidgetSteps = (): CoverWidgetStep[] => {
    return [
        {
            type: 'SOURCE',
            label: 'DIGITAL_WORKPLACE.ADMIN.COVER_WIDGET.DIALOG.SOURCE',
        },
        {
            type: 'SIZE',
            label: 'DIGITAL_WORKPLACE.ADMIN.COVER_WIDGET.DIALOG.SIZE',
        },
        {
            type: 'LAYOUT',
            label: 'DIGITAL_WORKPLACE.ADMIN.COVER_WIDGET.DIALOG.STYLE',
        },
    ];
};

export const getCountersForConfiguration = (
    definition: IPostMetadata | null,
): WidgetCounterConfiguration[] => {
    return [
        {
            id: 'showViewsCounter',
            label: 'DIGITAL_WORKPLACE.ADMIN.EDIT_WIDGET_DIALOG.CONTENT.VIEWS',
            disabled: false,
        },
        {
            id: 'showLikesCounter',
            label: 'DIGITAL_WORKPLACE.ADMIN.EDIT_WIDGET_DIALOG.CONTENT.LIKES',
            disabled: definition?.likeEnabled === false,
        },
        {
            id: 'showCommentsCounter',
            label: 'DIGITAL_WORKPLACE.ADMIN.EDIT_WIDGET_DIALOG.CONTENT.COMMENTS',
            disabled: false,
        },
        {
            id: 'showAttachmentsCounter',
            label: 'DIGITAL_WORKPLACE.ADMIN.EDIT_WIDGET_DIALOG.CONTENT.ATTACHMENTS',
            disabled: definition?.attachmentEnabled === false,
        },
        {
            id: 'showStandardTasksCounter',
            label: 'DIGITAL_WORKPLACE.ADMIN.EDIT_WIDGET_DIALOG.CONTENT.TASK',
            disabled: false,
        },
    ];
};

export const getUsefulLinksTypeForSelect = (): InputSelectItem[] => {
    return [
        {
            id: UsefulLinkTypeByDevice.BOTH,
            label: 'DIGITAL_WORKPLACE.ADMIN.USEFUL_LINK_WIDGET.CREATE_LINK_DIALOG.WEB_MOBILE',
        },
        {
            id: UsefulLinkTypeByDevice.WEB,
            label: 'DIGITAL_WORKPLACE.ADMIN.USEFUL_LINK_WIDGET.CREATE_LINK_DIALOG.WEB',
        },
        {
            id: UsefulLinkTypeByDevice.MOBILE,
            label: 'DIGITAL_WORKPLACE.ADMIN.USEFUL_LINK_WIDGET.CREATE_LINK_DIALOG.MOBILE',
        },
    ];
};

export const buildNewGroup = (link: UsefulLink): UsefulLinksGroup => {
    return {
        uuid: uuid(),
        name: '',
        links: [link],
    };
};

export const widgetCreateButtons = (): WidgetCreateButton[] => {
    return [
        {
            type: 'main-cover',
            label: 'DIGITAL_WORKPLACE.ADMIN.COVER_WIDGET.MAIN_COVER',
            hidden: false,
        },
        {
            type: 'banner',
            label: 'DIGITAL_WORKPLACE.ADMIN.COVER_WIDGET.BANNER',
            hidden: false,
        },
        {
            type: 'useful-link',
            label: 'DIGITAL_WORKPLACE.ADMIN.USEFUL_LINK_WIDGET.USEFUL_LINK',
            hidden: false,
        },
        {
            type: 'post-roll',
            label: 'DIGITAL_WORKPLACE.ADMIN.POST_ROLL_WIDGET.ADD_POST_ROLL',
            hidden: false,
        },
        {
            type: 'cta',
            label: 'DIGITAL_WORKPLACE.ADMIN.CTA_WIDGET.RAPID_ACTIONS',
            hidden: false,
        },
        {
            type: 'my-space',
            label: 'DIGITAL_WORKPLACE.ADMIN.MY_SPACE_WIDGET.MY_SPACE',
            hidden: false,
        },
    ];
};

export const ctaWidgetSteps = (): CtaWidgetStep[] => {
    return [
        {
            idx: 1,
            label: 'DIGITAL_WORKPLACE.ADMIN.CTA_WIDGET.CREATE_CTA_DIALOG.COMMUNITY',
        },
        {
            idx: 2,
            label: 'DIGITAL_WORKPLACE.ADMIN.CTA_WIDGET.CREATE_CTA_DIALOG.ACTION',
        },
        {
            idx: 3,
            label: 'DIGITAL_WORKPLACE.ADMIN.CTA_WIDGET.CREATE_CTA_DIALOG.CONTENT',
        },
        {
            idx: 4,
            label: 'DIGITAL_WORKPLACE.ADMIN.CTA_WIDGET.CREATE_CTA_DIALOG.POST_LIST',
        },
    ];
};

export const checkWidgetDifferencesForPreview = (
    prev: PostListWidget | undefined,
    current: PostListWidget,
): boolean => {
    if (!prev) return true;

    return (
        !areEqualObjects(
            prev.filters.workflowStatus,
            current.filters.workflowStatus,
        ) ||
        !areEqualObjects(
            prev.filters.dashboardWorkflowStatus,
            current.filters.dashboardWorkflowStatus,
        ) ||
        !areEqualObjects(prev.filters.postTypes, current.filters.postTypes) ||
        !areEqualObjects(
            prev.filters.postSurveyTypes,
            current.filters.postSurveyTypes,
        ) ||
        !areEqualObjects(prev.communityIds, current.communityIds)
    );
};

export const checkCallToActionDifferencesForPreview = (
    prev: CallToAction | undefined,
    actual: CallToAction,
): boolean => {
    if (!prev) return true;

    const prevFilters = prev.examplePostData?.filters;
    const actualFilters = actual.examplePostData?.filters;

    return (
        (!prev.showExamplePost && actual.showExamplePost) ||
        prevFilters !== actualFilters ||
        !areEqualObjects(
            prevFilters?.workflowStatus,
            actualFilters?.workflowStatus,
        ) ||
        !areEqualObjects(
            prevFilters?.dashboardWorkflowStatus,
            actualFilters?.dashboardWorkflowStatus,
        ) ||
        prev.communityId != actual.communityId
    );
};

export const staticCoverContentFillTypeOptions: StaticCoverContentFillTypeOption[] =
    [
        {
            type: 'fit',
            label: 'DIGITAL_WORKPLACE.ADMIN.COVER_WIDGET.ADD_CONTENTS_DIALOG.FILL_TYPE.FIT',
            subtitle:
                'DIGITAL_WORKPLACE.ADMIN.COVER_WIDGET.ADD_CONTENTS_DIALOG.FILL_TYPE.FIT_SUBTITLE',
        },
        {
            type: 'fill',
            label: 'DIGITAL_WORKPLACE.ADMIN.COVER_WIDGET.ADD_CONTENTS_DIALOG.FILL_TYPE.FILL',
            subtitle:
                'DIGITAL_WORKPLACE.ADMIN.COVER_WIDGET.ADD_CONTENTS_DIALOG.FILL_TYPE.FILL_SUBTITLE',
        },
    ];

export const staticCoverContentAlignmentOptions: StaticCoverContentAlignmentOption[] =
    [
        {
            type: 'center',
            label: 'DIGITAL_WORKPLACE.ADMIN.COVER_WIDGET.ADD_CONTENTS_DIALOG.ALIGNMENT.CENTER',
        },
        {
            type: 'top',
            label: 'DIGITAL_WORKPLACE.ADMIN.COVER_WIDGET.ADD_CONTENTS_DIALOG.ALIGNMENT.TOP',
        },
        {
            type: 'bottom',
            label: 'DIGITAL_WORKPLACE.ADMIN.COVER_WIDGET.ADD_CONTENTS_DIALOG.ALIGNMENT.BOTTOM',
        },
        {
            type: 'right',
            label: 'DIGITAL_WORKPLACE.ADMIN.COVER_WIDGET.ADD_CONTENTS_DIALOG.ALIGNMENT.RIGHT',
        },
        {
            type: 'left',
            label: 'DIGITAL_WORKPLACE.ADMIN.COVER_WIDGET.ADD_CONTENTS_DIALOG.ALIGNMENT.LEFT',
        },
    ];

export function isAttachmentImage(
    attachment: StaticCoverWidgetContentLocal | null,
): boolean {
    return attachment ? isMediaImage(attachment.contentMimeType) : false;
}

export function defaultCoverWidgetMobileContentAttachment(
    webContent: StaticCoverWidgetMobileContentAttachment,
): StaticCoverWidgetMobileContentAttachment | null {
    return isMediaImage(webContent.contentMimeType)
        ? {
              ...webContent,
              fillType: 'fill',
              alignment: 'center',
              webMobileSameImage: true,
          }
        : null;
}
