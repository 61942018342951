import { Injectable, inject } from '@angular/core';
import { AuthService } from '@interacta-shared/data-access-auth';
import { ConsoleService } from '@interacta-shared/data-access-configuration';
import {
    EMPTY_SEARCH_VALUE,
    isCurrentUser,
    mapArrayById,
} from '@interacta-shared/util';
import { CustomFieldType } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';
import { HomeCommunityIndex, IUser, IUsersGroup } from '@modules/core';
import {
    getCurrentUserPlaceholder,
    getEmptyGroupPlaceholder,
    getEmptyUserPlaceholder,
} from '@modules/core/models/member/member.utils';
import { UsersService } from '@modules/core/services/users.service';
import {
    CustomFieldsFilterDateOptions,
    IPostFilters,
    IPostFiltersCustomField,
} from '@modules/post/models/filter-post/filter-post.model';
import { getEmptyPostLinkPlaceholder } from '@modules/post/models/post-link/post-link.model';
import { PostService } from '@modules/post/services/post.service';
import { CommunitiesStateService } from '@modules/state/services/communities-state.service';
import { TranslateService } from '@ngx-translate/core';
import {
    addDays,
    endOfDay,
    isSameDay,
    isSameMinute,
    startOfDay,
    subDays,
} from 'date-fns';
import { Observable, forkJoin, map, mergeMap, of } from 'rxjs';
import {
    DateCustomFieldFilterInput,
    DateCustomFieldFilterOutput,
} from '../components/dashboard-filters-date-time/dashboard-filters-date-time.component';

type ActualizationObservable = Observable<{
    key: keyof IPostFilters;
    value: IUser[] | IUsersGroup[] | IHashTag[] | IPostFiltersCustomField[];
}>;

export interface ActualizeFiltersOptions {
    remapCurrentUserSearch: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class ActualizeFiltersService {
    private readonly usersService = inject(UsersService);
    private readonly communitiesStateService = inject(CommunitiesStateService);
    private readonly postService = inject(PostService);
    private readonly translateService = inject(TranslateService);
    private readonly authService = inject(AuthService);
    private readonly consoleService = inject(ConsoleService);

    actualizeFilters$<T extends { filters: Partial<IPostFilters> }>(
        item: T,
        communityId: HomeCommunityIndex,
        options?: ActualizeFiltersOptions,
    ): Observable<T> {
        const filters = item.filters;
        const currentUser = this.authService.getCurrentUserData();
        const actualizationObs$: Array<ActualizationObservable> = [];

        if (filters.createdByUser && filters.createdByUser.length) {
            const currentUserIsPresent = filters.createdByUser.some((user) =>
                isCurrentUser(user.id),
            );

            const current = !currentUserIsPresent
                ? null
                : options?.remapCurrentUserSearch && currentUser
                  ? currentUser
                  : getCurrentUserPlaceholder(this.getCurrentUserLabel());

            const ids = currentUserIsPresent
                ? mapArrayById(filters.createdByUser)!.filter(
                      (id) => !isCurrentUser(id),
                  )
                : mapArrayById(filters.createdByUser)!;

            const obs: ActualizationObservable = (
                ids.length ? this.usersService.getUsersDetailBatch(ids) : of([])
            ).pipe(
                map((users) => ({
                    key: 'createdByUser',
                    value: current ? [current, ...users] : users,
                })),
            );

            actualizationObs$.push(obs);
        }

        if (filters.createdByGroup?.length) {
            const ids = mapArrayById(filters.createdByGroup);
            if (ids) {
                const obs: ActualizationObservable = this.usersService
                    .getGroupsDetailBatch(ids)
                    .pipe(
                        map((groups) => ({
                            key: 'createdByGroup',
                            value: groups,
                        })),
                    );

                actualizationObs$.push(obs);
            }
        }

        if (filters.attachmentCreatedByUser?.length) {
            const ids = mapArrayById(filters.attachmentCreatedByUser);
            if (ids) {
                const obs: ActualizationObservable = this.usersService
                    .getUsersDetailBatch(ids)
                    .pipe(
                        map((users) => ({
                            key: 'attachmentCreatedByUser',
                            value: users,
                        })),
                    );

                actualizationObs$.push(obs);
            }
        }

        //Community aware observables
        if (typeof communityId === 'number') {
            if (filters.hashtag?.length) {
                const ids = mapArrayById(filters.hashtag);

                const obs: ActualizationObservable =
                    this.communitiesStateService
                        .getPostMetadata(communityId)
                        .pipe(
                            map((postDefinition) =>
                                postDefinition.hashtags.filter((h) =>
                                    ids?.includes(h.id),
                                ),
                            ),
                            map((hashtags) => ({
                                key: 'hashtag',
                                value: hashtags,
                            })),
                        );
                actualizationObs$.push(obs);
            }

            if (filters.attachmentHashtag?.length) {
                const ids = mapArrayById(filters.attachmentHashtag);

                const obs: ActualizationObservable =
                    this.communitiesStateService
                        .getPostMetadata(communityId)
                        .pipe(
                            map((postDefinition) =>
                                postDefinition.hashtags.filter((h) =>
                                    ids?.includes(h.id),
                                ),
                            ),
                            map((hashtags) => ({
                                key: 'attachmentHashtag',
                                value: hashtags,
                            })),
                        );
                actualizationObs$.push(obs);
            }

            if (filters.customFields?.length) {
                const fieldObs$ = this.getCustomFieldsObs(
                    filters.customFields,
                    communityId,
                    'post',
                    options,
                );

                const obs$: ActualizationObservable = forkJoin(fieldObs$).pipe(
                    map((customFields) => ({
                        key: 'customFields',
                        value: customFields,
                    })),
                );

                actualizationObs$.push(obs$);
            }

            if (filters.screenFields?.length) {
                const fieldObs$ = this.getCustomFieldsObs(
                    filters.screenFields,
                    communityId,
                    'screen',
                    options,
                );

                const obs$: ActualizationObservable = forkJoin(fieldObs$).pipe(
                    map((screenFields) => ({
                        key: 'screenFields',
                        value: screenFields,
                    })),
                );

                actualizationObs$.push(obs$);
            }
        }

        return actualizationObs$.length === 0
            ? of(item)
            : forkJoin(actualizationObs$).pipe(
                  map((values) => ({
                      ...item,
                      filters: {
                          ...filters,
                          ...values.reduce(
                              (accumulator, value) => ({
                                  ...accumulator,
                                  [value.key]: value.value,
                              }),
                              {} as Partial<IPostFilters>,
                          ),
                      },
                  })),
              );
    }

    private getCustomFieldsObs(
        fields: IPostFiltersCustomField[],
        communityId: number,
        type: 'post' | 'screen',
        options?: ActualizeFiltersOptions,
    ): Observable<IPostFiltersCustomField>[] {
        return fields.map((cf) => {
            return this.communitiesStateService
                .getPostMetadata(communityId)
                .pipe(
                    map((postDefinition) => {
                        const fieldMetadatas =
                            type === 'post'
                                ? postDefinition.fieldMetadatas
                                : postDefinition.workflowDefinition
                                      ?.screenFieldMetadatas || [];

                        const definition = fieldMetadatas.find(
                            (f) => f.id === cf.definition.id,
                        );

                        if (!definition) {
                            this.consoleService.warningDev(
                                `No definition found for field ${cf.definition.id}`,
                            );
                        }

                        return {
                            definition: definition ?? cf.definition,
                            value: cf.value,
                        };
                    }),
                    mergeMap((cf) =>
                        this.actualizePostFilterCustomFieldValue$(cf, options),
                    ),
                );
        });
    }

    private actualizePostFilterCustomFieldValue$(
        field: IPostFiltersCustomField,
        options?: ActualizeFiltersOptions,
    ): Observable<IPostFiltersCustomField> {
        if (!field.value) {
            return of(field);
        }

        switch (field.definition.customFieldType) {
            case CustomFieldType.USER_PICKER: {
                const ids = field.value as (number | 'current')[];
                const placeholderUsers: IUser[] = [];

                if (ids.includes(EMPTY_SEARCH_VALUE)) {
                    placeholderUsers.push(
                        getEmptyUserPlaceholder(this.getNotAssignedLabel()),
                    );
                }

                if (ids.some((id) => isCurrentUser(id))) {
                    const currentUser = this.authService.getCurrentUserData();

                    const currentUserPlaceholder: IUser =
                        options?.remapCurrentUserSearch && currentUser != null
                            ? currentUser
                            : getCurrentUserPlaceholder(
                                  this.getCurrentUserLabel(),
                              );
                    placeholderUsers.push(currentUserPlaceholder);
                }

                const numericIds = ids.filter(
                    (id) => id != EMPTY_SEARCH_VALUE && !isCurrentUser(id),
                ) as number[];

                const obs$ = numericIds.length
                    ? this.usersService.getUsersDetailBatch(numericIds)
                    : of([]);
                return obs$.pipe(
                    map((users) => ({
                        ...field,
                        value: [...placeholderUsers, ...users],
                    })),
                );
            }
            case CustomFieldType.GROUP_PICKER: {
                const ids = field.value as number[];
                return this.usersService.getGroupsDetailBatch(ids).pipe(
                    map((groups) => ({
                        ...field,
                        value: ids.includes(EMPTY_SEARCH_VALUE)
                            ? [
                                  getEmptyGroupPlaceholder(
                                      this.getNotAssignedLabel(),
                                  ),
                                  ...groups,
                              ]
                            : groups,
                    })),
                );
            }
            case CustomFieldType.POST_PICKER: {
                const ids = field.value as number[];
                return this.postService.getBasePostLiteBatch(ids).pipe(
                    map((posts) => ({
                        ...field,
                        value: ids.includes(EMPTY_SEARCH_VALUE)
                            ? [getEmptyPostLinkPlaceholder(), ...posts]
                            : posts,
                    })),
                );
            }
            case CustomFieldType.DATE:
            case CustomFieldType.DATETIME:
                return of({
                    ...field,
                    value: this.actualizeDateCustomFiltersValue(
                        field.value as any,
                        field.definition.customFieldType,
                    ),
                });
            default:
                return of(field);
        }
    }

    private getNotAssignedLabel(): string {
        return this.translateService.instant('DASHBOARD.FILTERS.NOT_ASSIGNED');
    }

    private getCurrentUserLabel(): string {
        return this.translateService.instant('DASHBOARD.FILTERS.CURRENT_USER');
    }

    private actualizeDateCustomFiltersValue(
        fieldValue: any,
        fieldType: CustomFieldType,
    ): DateCustomFieldFilterOutput | null {
        /* fieldValue is a representation for DateCustomFieldFilterInput 
    except for 'value' property that needs to be converted in Date object
    */
        const typedValue: Omit<DateCustomFieldFilterInput, 'value'> & {
            value: string | number | string[] | number[] | null;
        } = fieldValue;
        const now = new Date();
        const daysCount = typedValue.daysCount ?? 7;
        const includeToday = typedValue.includeToday;
        const type = typedValue.type;
        if (type == null) return null;
        let result: DateCustomFieldFilterOutput | null = null;
        const isDate = fieldType === CustomFieldType.DATE;

        switch (+type) {
            case CustomFieldsFilterDateOptions.TODAY:
                result = {
                    value: isDate ? now : [startOfDay(now), endOfDay(now)],
                    type,
                    daysCount: null,
                    includeToday: false,
                };
                break;
            case CustomFieldsFilterDateOptions.LAST_X_DAYS: {
                const value = [
                    startOfDay(subDays(now, daysCount)),
                    endOfDay(subDays(now, includeToday ? 0 : 1)),
                ];

                result = {
                    value,
                    type,
                    daysCount,
                    includeToday,
                };
                break;
            }
            case CustomFieldsFilterDateOptions.NEXT_X_DAYS: {
                const value = [
                    startOfDay(addDays(now, includeToday ? 0 : 1)),
                    endOfDay(addDays(now, daysCount)),
                ];

                result = {
                    value,
                    type,
                    daysCount,
                    includeToday,
                };
                break;
            }
            case CustomFieldsFilterDateOptions.CUSTOM: {
                const start =
                    Array.isArray(typedValue.value) && typedValue.value[0]
                        ? new Date(typedValue.value[0])
                        : null;
                const end =
                    Array.isArray(typedValue.value) &&
                    typedValue.value.length > 1
                        ? new Date(typedValue.value[1])
                        : null;
                let value: DateCustomFieldFilterOutput['value'] = now;

                if (!start && !end) {
                    value = null;
                } else {
                    if (isDate) {
                        value =
                            start && end && isSameDay(start, end)
                                ? start
                                : [start, end];
                    } else {
                        value =
                            start && end && isSameMinute(start, end)
                                ? start
                                : [start, end];
                    }
                }

                result = {
                    value,
                    type,
                    daysCount: null,
                    includeToday: false,
                };
                break;
            }
            default:
                throw Error('Key Not Valid');
        }

        return result;
    }
}
