import { WorkspaceBase } from '@modules/communities/models/workspace.model';
import {
    DigitalWorkplaceDefinition,
    StaticContentImageAlignment,
    StaticContentImageFillType,
    StaticCoverWidgetContent,
    WidgetCardSettingsBase,
} from '@modules/digital-workplace/models/digital-workplace.model';

export interface DigitalWorkplaceAdminCapabilities {
    global: boolean;
    workspaces: WorkspaceBase[];
}

export interface DigitalWorkplaceApiResponse<T> {
    item: T;
    occToken: number;
}

export interface WidgetCounterConfiguration {
    id: keyof WidgetCardSettingsBase;
    label: string;
    disabled: boolean;
}

export interface CoverWidgetStep {
    type: 'SOURCE' | 'SIZE' | 'LAYOUT';
    label: string;
}

export interface CtaWidgetStep {
    idx: number;
    label: string;
}

export interface DigitalWorkplaceStatusProperties {
    isDraft: boolean;
    isOnline: boolean;
    isOffline: boolean;
    isOnlineDirty: boolean;
    isOfflineDirty: boolean;
}

export type StaticCoverSource = 'local' | 'youtube' | 'vimeo';

export type StaticCoverWidgetContentLocal = Required<
    Pick<
        StaticCoverWidgetContent,
        'contentMimeType' | 'contentRef' | 'temporaryContentViewLink'
    >
>;

export type StaticCoverAttachmentRatio =
    | 'web_16/9'
    | 'web_hero'
    | 'web_banner'
    | 'mobile_hero'
    | 'mobile_banner';

export type StaticCoverAttachmentViewMode = 'full' | 'split';

export type CreateHomeData = Pick<
    DigitalWorkplaceDefinition,
    'name' | 'visibility'
>;

export type PublishHomeData = Pick<
    DigitalWorkplaceDefinition,
    'id' | 'visibility' | 'publishedHomeId'
>;

export type MoveHomeData = Pick<DigitalWorkplaceDefinition, 'id' | 'position'>;

export type DuplicateHomeData = Pick<DigitalWorkplaceDefinition, 'id' | 'name'>;

export enum UsefulLinkTypeByDevice {
    BOTH = 1,
    WEB = 2,
    MOBILE = 3,
}

export type WidgetCreateButtonType =
    | 'post-roll'
    | 'main-cover'
    | 'banner'
    | 'useful-link'
    | 'cta'
    | 'my-space';

export interface WidgetCreateButton {
    type: WidgetCreateButtonType;
    label: string;
    hidden: boolean;
}

export type HomePreviewMode = 'draft' | 'published';

export interface StaticCoverContentFillTypeOption {
    type: StaticContentImageFillType;
    label: string;
    subtitle: string;
}

export interface StaticCoverContentAlignmentOption {
    type: StaticContentImageAlignment;
    label: string;
}
